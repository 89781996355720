@import '../../../../style/sass/main.scss';

.tablegrid {

    .k-grid.k-widget,
    .k-grid.k-grid-md,
    .k-table-md {
        font-size: 1.1rem !important;

        th.k-table-th {
            text-overflow: inherit;
            word-break: break-all;
            white-space: inherit;
            vertical-align: middle !important;

            .k-column-title {
                text-overflow: inherit;
                word-break: break-all;
                white-space: inherit;
                vertical-align: middle !important;
            }
        }

        .k-column-title {
            font-size: 1.25rem !important;
            font-weight: 600;
        }

        .k-pager-info {
            display: block !important;
        }

        .k-grid-content {
            overflow: hidden
        }

        .k-grid-header {
            padding: 0;
        }
    }

    .tablegrid-status {
        @include flexCenter;
    }

    .tablegrid-status-active,
    .tablegrid-status-inactive {
        border-radius: 0.625rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 1rem 1rem;
        @include flexCenter;
        width: 6.25rem;
    }

    .tablegrid-status-active {
        border: 2px solid $status-green;
        color: $status-green;
    }

    .tablegrid-status-inactive {
        border: 2px solid $status-red;
        color: $status-red;
    }

    .tablegrid-switch {
        text-align: center;
    }

    .tablegrid-heading {
        color: $secondary-color;
        background: $table-header;
        padding: 1rem 1.3125rem !important;

        .k-icon {
            color: $secondary-color !important;
        }
    }

    .tablegrid-heading-no-sorting {
        .k-link {
            cursor: default;
        }
    }
}