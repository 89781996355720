@import '../../../style/sass/main.scss';

.client-listing {
    width: 100%;
    background: $white-color;

    .client-listing-content {
        width: 100%;
        background: $white-color;
        padding: 1.25rem;
        border-radius: 0.625rem;
        box-shadow: $box-table-shadow;
    }

    .client-listing-toolbar {
        margin-bottom: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn.disabled {
            opacity: 0.20;
        }

        .css-15l1327-control {
            min-height: 0;
            max-height: 3.52rem !important;
        }
    }

    .client-listing-toolbar-left,
    .client-listing-toolbar-right {
        display: flex;
        align-items: center;

        .secondary-button {
            border-radius: 0.625rem;
        }
    }

    .client-listing-toolbar-left {
        .client-listing-toolbar-left-refresh {
            color: $primary-color;
            background: $link-color-1;
            padding: 1.35rem 1.25rem;
            border-radius: 0.4375rem;
            @include flexCenter;
            @include cursorPointer;
        }

        .show__value-container {
            padding: 13px;
        }
    }

    .client-listing-toolbar-right {
        .client-listing-toolbar-right-edit {
            color: $primary-color;
            background: $link-color-1;
            padding: 0.97rem 0.875rem;
            border-radius: 0.4375rem;
            @include flexCenter;
            @include cursorPointer;
            margin-right: 5px;
        }

        .client-listing-toolbar-right-edit.disabled {
            color: $text-color-3;
            background: $link-color-1;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    .css-1ufn0jl,
    .css-15l1327-control {
        border-radius: 0.625rem;
    }
}

.fileIconSize {
    font-size: 5rem !important;
}