@import "../../style/sass/main.scss";

.csv-download-button {
  a {
    color: $primary-color !important;
  }
}

.client-listing-toolbar-left-show {
  .mySelect__placeholder,
  .mySelect__indicator,
  .mySelect__single-value {
    margin-top: -0.35rem;
  }

  .mySelect__control--is-focused {
    max-height: 3.52rem !important;
  }
}

.minWidth {
  min-width: 18.8rem;
}
