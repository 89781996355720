@import '../../../style/sass/main.scss';

.client-creation {
    height: 100%;
    display: flex;
    flex-direction: column;
     

    .client-creation-container {
        flex: 1;
        width: 100%;
        background: $white-color;
        padding: 1.25rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .client-creation-mobile {
            font-family: 'Montserrat', sans-serif !important;

            input {
                padding: 1.25rem;
                border: 1px solid #e6ebf0;
                border-radius: 4px;
                width: 100%;
                font-size: 1rem;
            }
        }

        .client-creation-select {
            height: 4.175rem;
        }

        .mySelect__placeholder {
            font-family: 'Montserrat', sans-serif;
            color: $grey-1;
        }

        .mySelect__single-value {
            font-size: 1rem;
            color: $black-color;
            font-family: 'Montserrat', sans-serif;
        }

        .mySelect__menu {
            z-index: 10000;
        }

        .client-creation-input {
            border: 2px solid rgba(0, 0, 0, 0.15) !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.40rem;
            border-radius: 0.375rem;

            &:focus {
                outline: 1px solid rgba(0, 0, 0, 0.15);
            }

            span {
                color: #D5D9DD;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .client-creation-input-label {
                background: #EDEDED;
                padding: 0.6rem 0.75rem;
                color: #8D939B;
                border-radius: 5px;
                font-weight: 600;
                font-size: 1rem;
                @include cursorPointer;
            }

        }

        .client-helper-text {
            font-size: 0.8rem;
            margin-left: 1rem;
            color: gray;
        }

        .client-creation-helper-text {
            padding-top: 0.1rem;
            // font-size: 0.875rem;
            line-height: 0.875rem;
            color: #ACB2BA;
            width: 100%;
            font-size: 500;
            float: right;
            text-align: right;
        }

        .srv-validation-message {
            padding-top: 0.2rem;
        }

        .client-creation-submit-container {
            margin-left: auto;
        }

    }

    .product-select {
        .mySelect__value-container{
             overflow: auto;
        }
    }
}