// App.scss
$primary: #E94F1C;
$grid-bg: #fff;

@import "~@progress/kendo-theme-default/dist/all.scss";

.warning {
    color: red !important;
}

// .creation-container {
//     background: $grey-4 ;
// }

// .creation-container-body {
//     background: $white-color;
//     padding: 2rem 1.25rem;
//     border-radius: 0.625rem;
//     // padding-top: 2rem;
// }
