// Font weights
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$zindex: 9999;

$fontFamily: "Montserrat";

// Logo Styling
.logo-3,
.logo-sc {
  font-size: 8rem;
  line-height: 13rem;
  font-weight: $bold;
  // font-family: 'Montserrat';
}

.logo-3 {
  color: $primary-color;
}

.logo-sc {
  color: $secondary-color;
}

.remove-underline {
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.common-heading {
  color: $primary-color;
  font-size: 1.25rem;
  font-weight: $semiBold;
  line-height: 1.5rem;
}

.section-heading {
  font-weight: $bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $secondary-color;
}

.section-subheading {
  font-weight: $semiBold;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $text-disabled;
}

.grey-heading-500,
.grey-heading-600 {
  color: $grey-2;
  font-size: 1rem;
  line-height: 1.25rem;
}

.grey-heading-500 {
  font-weight: $medium;
}

.grey-heading-600 {
  font-weight: $semiBold;
}

.section-breadcrumbs {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: $regular;

  .active {
    color: $primary-color;
  }
}

.section-breadcrumbs-a {
  color: $text-disabled;

  &:hover {
    color: $primary-color;
  }
}

// Mui Text Field Global Styling
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: $grey-1 !important;
}

label.MuiInputLabel-root,
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  color: $text-color !important;
  // z-index: 0;
  font-size: 1.2rem;
  font-weight: $medium;
  font-family: "Montserrat", sans-serif;
}

.primary-button {
  background: $primary-color;
  color: $white-color;
  border-radius: 0.625rem;
  padding: 0.9375rem 1.1875rem;
  font-size: 1rem;
  font-weight: $semiBold;
  line-height: 1.25rem;

  &:hover {
    color: $white-color;
  }
}

.secondary-button {
  background: $link-color-1;
  color: $primary-color !important;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 10px;
  padding: 1rem;
  font-weight: $semiBold;
}

.light-button {
  background: $white-color !important;
  color: $primary-color;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 10px;
  padding: 1rem;
  font-weight: $semiBold;
}

.cancel-button {
  border: 1px solid $primary-color;
  padding: 0.9375rem 1.375rem;
  color: $primary-color;
  line-height: 1.25rem;
  font-weight: $semiBold;
  border-radius: 0.625rem;
}

.mr-20 {
  margin-right: 1.25rem !important; //20px
}

.ml-20 {
  margin-left: 1.25rem !important; //20px
}

.mt-20 {
  margin-top: 1.25rem !important; //20px
}

.mt-10 {
  margin-top: 0.625rem !important; //10px
}

.ml-10 {
  margin-left: 0.625rem !important; //10px
}

.mr-10 {
  margin-left: 0.625rem !important; //10px
}

.k-grid-header.k-header {
  color: $white-color;
  background: $primary-color;
  padding: 28px 21px;
}

.srv-validation-message {
  color: red;
  font-size: 12px;
}

.phone-input.PhoneInput-focus {
  border: none;
}

.phone-input {
  border: 0.2rem solid $input-border-color;
  border-radius: 0.375rem;
  padding: 1rem;
  font-family: "Montserrat", sans-serif !important;

  input {
    border: none;
    font-family: "Montserrat", sans-serif !important;

    &:focus-visible {
      outline: none !important;
    }
  }
}

.mySelect__control {
  border-radius: 0.5rem !important;
  border: 0.2rem solid $input-border-color !important;
  box-shadow: none !important;
  max-height: 3.8rem !important;
  min-width: 14rem !important;
  min-height: unset !important;
}

.mySelect__value-container {
  height: 3.4375rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;

  .mySelect__multi-value__label {
    background-color: $role-permission-background;
  }

  .mySelect__multi-value__remove {
    background-color: $role-permission-background;

    &:hover {
      background-color: $role-permission-cross-background;
      color: $primary-color;
    }
  }

  // overflow-y: scroll !important;
}

.mySelect__value-container::-webkit-scrollbar {
  width: 0.313rem;
}

.mySelect__placeholder {
  color: $grey-1;
  font-family: "Montserrat", sans-serif;
}

.mySelect__single-value {
  font-size: 0.875rem;
  color: $black-color;
  font-family: "Montserrat", sans-serif;
}

.mySelect__menu {
  z-index: 100 !important;

  .mySelect__option--is-focused:active {
    background-color: $grey-3;
  }

  .mySelect__option--is-selected {
    color: $primary-color;
    background-color: unset !important;
  }

  .css-10wo9uf-option:active {
    background-color: white !important;
  }
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 0.9rem 1.16rem;
}

.k-window-content.k-dialog-content {
  padding: 1rem 1.3rem;
  padding-top: 1rem !important;
}

.admin-toolbar-search {
  .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    bottom: 0rem !important;
    top: -0.46rem !important;
    border: 0.2rem solid $input-border-color !important;
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    height: 1.5rem;
    padding: 1rem 0.5rem;
  }

  .MuiInputLabel-root {
    margin-top: 0.3rem;
  }
}

#panel1a-header {
  background-color: $table-header;
  color: $secondary-color;
}

#panel1a-header.Mui-expanded {
  min-height: auto;
}

.k-switch-sm,
.k-switch-sm .k-switch-track {
  width: 3.5rem !important;
  height: 1.8rem !important;
}

.k-switch-sm .k-switch-thumb {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 0.8rem - 0rem) !important;
}

.k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(0.8rem + 0rem) !important;
}

.k-grid,
.k-grid-header,
.k-header,
th.k-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-grid td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>td,
.k-filter-row>th {
  border-color: $grey-1 !important;
}

.k-table-tbody {
  .k-table-row td {
    border-bottom: 0.1rem solid $grey-1;
  }
}

.btn-check:focus+.btn,
.btn:focus,
.k-button-solid-base:focus,
.k-button-solid-base.k-focus {
  box-shadow: none !important;
}

.download-template-custom-class {
  margin: 0.75rem 0;
  background-color: $grey-5 !important;
  font-weight: bold;
  font-size: 0.833rem !important;
  border-radius: 0.5rem !important;
  color: $primary-color !important;
  padding: 0.76rem 0.5833rem !important;
}

.custom-dialog-container {
  height: 16.67rem;
  margin-top: 1.33rem;
}

.custom-span {
  font-size: 1.1rem;
}

.download-template-btn {
  margin-top: 0.9rem !important;
  margin-bottom: 0.9rem !important;
  background-color: $grey-5 !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  border-radius: 0.6rem !important;
  color: $primary-color !important;
  margin-left: 1.4rem !important;
  padding: 0.7rem !important;
}

.download-csv-btn {
  background-color: $primary-color !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  border-radius: 0.6rem !important;
  color: $white-color !important;
  margin-left: 8rem !important;
  padding: 0.8rem !important;
  margin-right: 1rem !important;
}

.multi-reselect {
  .mySelect__value-container {
    :first-child {
      border: none !important;
    }
  }
}

// phone number input styling
.bu-helper-text-phone-number {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: gray;
}