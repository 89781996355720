@import '../../../../style/sass/main.scss';

.tablegrid {
  .tablegrid-status {
    @include flexCenter;
  }

  .tablegrid-status-active,
  .tablegrid-status-inactive {
    border-radius: 0.625rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.813rem 0.688rem;
    @include flexCenter;
    width: 6.25rem;
  }

  .tablegrid-status-active {
    border: 2px solid $status-green;
    color: $status-green;
  }

  .tablegrid-status-inactive {
    border: 2px solid $status-red;
    color: $status-red;
  }

  .tablegrid-switch {
    text-align: center;
  }

  .tablegrid-heading {
    // width: 100%;
    color: $secondary-color;
    background: $table-header;
    // border: 1px solid $grey-1;

    .k-icon {
      color: $secondary-color !important;
    }
  }

  input[type=checkbox] {
    accent-color: red;
  }

  tr {
    td {
      color: $table-text-color;
    }
  }
}
.btnteam {
  background: $primary-color !important;
  color: #fff !important;
}

.editteam {
  background-color: $grey-3;
  color: $primary-color;
}
.editteam:hover {
  background: none !important;
}
