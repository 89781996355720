@import '../../../style/sass/main.scss';

.k-button.download-template {
    margin: 0.625rem 0;
    margin-left: 0.875rem;
    background-color: $grey-5 ;
    font-weight: bold;
    font-size: 0.625rem;
    border-radius: 0.375rem;
    padding: 0.4375rem;
}
