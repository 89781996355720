@import '../../../style/sass/main.scss';

.k-panelbar>.k-panelbar-header>.k-link {
    padding: 0 !important;
}

.paneluser th {
    background-color: $primary-color;
    min-width: 13.625rem;
    color: #fff;
    padding: 0.8125rem 0.5rem;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.paneluser th:first-child {
    padding-left: 1.25rem !important;
}

.action-th {
    min-width: 17.66rem !important;
}

.panedata {
    width: 100%;
}

.k-panelbar>.k-panelbar-header>.k-link.k-selected {
    background: #fff !important;
    color: #000 !important;
}

.panellevel {
    width: 100%;
}

.panellevel td {
    min-width: 13.625rem;
    padding-left: 0.6875rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.panellevel td:first-child {
    padding-left: 1.25rem !important;
}

.panellevel td span {
    padding-left: 0;
    margin-right: 0.625rem;
    text-align: unset !important;
}

.panelevel1 td {
    text-align: left;
    background-color: $primary-color;
    color: #fff;
    padding: 0.5625rem;
    border-right: 1px solid;
    border-bottom: 1px solid;
    min-width: 13.625rem;
    vertical-align: middle;
}

.panelevel1 td:last-child {
    padding-right: 0 !important;
}

.panelevel1 td:first-child {
    background-color: #fff;
}

.panedata .k-icon {
    background-color: $primary-color;
    color: #fff !important;
    position: absolute;
    left: 1rem;
    top: 0;
    margin-top: 0.875rem;
    display: none;

}

.panellevel span .svg-inline--fa {
    background-color: $primary-color;
    color: #fff !important;
    margin-left: 0.625rem;
    padding: 0.3125rem;
    font-size: 1.125rem;
}

.panelevel1 td .k-icon {
    right: 0;
    background: none;
    margin-right: 0;
    color: $primary-color !important
}

.panedata .k-i-chevron-down:before {
    content: "\e145";
}

.panedata .k-i-chevron-up:before {
    content: "\e146";
}

// Upload CSV
.k-window {
    -webkit-border-radius: 1.25rem !important;
    border-radius: 1.25rem !important;
}

.span1 {
    color: $primary-color;
    font-weight: bold;
}

.k-btn1 {
    color: #B6B6B4;
    float: right;
    margin-top: 0;
    @include cursorPointer;
}

.border2 {
    margin: 1.25rem;
    border: 2px dashed;
    border-radius: 0.625rem;
    color: #B6B6B4;
}

.div2 {
    margin: 1.25rem;

}

.k-span {
    color: #B6B6B4;
    margin-left: 45%;
    margin-top: 1px;
    margin-bottom: 0.75rem;
    font-size: 3.125rem;
}

.kh4 {
    color: $primary-color;
    font-weight: bold;
    text-align: center;
}

.kp {
    color: #B6B6B4;
    font-weight: lighter;
    font-size: 0.875rem;
    text-align: center;
}


.bu-csv-error-container {
    max-height: 15.625rem;
    width: 31.25rem;
    overflow-y: auto;
}

.csv-download-button {
    a {
        color: $primary-color !important;
    }
}

.setup-table {
    width: 100%;
    table-layout: fixed;

    .setup-actions-button {
        margin-right: 0.5rem;
        font-size: 1rem;
        color: $primary-color;
        border-color: $primary-color;
    }

    .setup-con-actions {
        margin-right: 1rem;
        vertical-align: middle;

        i {
            font-size: 1.5rem;
            color: $primary-color;
            background: $white-color;
        }
    }

    th {
        font-family: $fontFamily;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1rem;
        color: $secondary-color;
        background: $table-header;
        padding: 1rem;
        border: 1px solid $grey-1 !important;
        vertical-align: middle;
        text-align: left;

        &.BU-active-width {
            width: 15.55rem;
        }

        &.BU-action-width {
            width: 24rem;
        }
    }

    td {
        width: min-content;
        padding: 1rem;
    }

    tbody {
        border-color: $grey-1;
    }

    .sub-td {
        font-family: $fontFamily;
        color: $secondary-color;
        background: $table-header;
        padding: 0.5rem 1rem;
        border: 1px solid $grey-1;
        vertical-align: middle;
        word-wrap: break-word;

        &:last-child {
            text-align: center;
        }

        &.align-item-center {
            text-align: center;
        }

        .k-switch {
            margin-right: .5rem;
        }

        .k-switch-on .k-switch-track {
            background-color: $primary-color !important;
        }
    }
}