@import '../../../style/sass/main.scss';

.bu-creation {
    height: 100%;
    display: flex;
    flex-direction: column;

    min-height: calc(100vh - 64px);

    .bu-create-container {
        @include flexSpaceBetween;
    }

    .bu-helper-text {
        font-size: 0.8rem;
        margin-left: 1rem;
        color: gray;
    }

    .bu-create-delete {
        float: right;
        background: $border-color;
        border: none;
        border-radius: 0.25rem;
        padding: 0.5rem;
        color: $primary-color;
    }

    .bu-create-panelbar {

        span.k-link,
        span.k-link.k-selected {
            background: $primary-color !important;
            color: $white-color !important;
            padding: 1rem !important;
            border-radius: 0.625rem !important;
        }

        span.k-icon {
            color: $white-color !important;
        }

        .k-animation-container.k-animation-container-relative {
            overflow: visible !important;
        }
    }

    .bu-creation-container {
        position: relative;
        flex: 1;
        width: 100%;
        background: $white-color;
        padding: 1.25rem;

        display: flex;
        flex-direction: column;

        .bu-creation-mobile {
            font-family: 'Montserrat', sans-serif !important;

            input {
                padding: 1.25rem;
                border: 1px solid #e6ebf0;
                border-radius: 4px;
                width: 100%;
                font-size: 1rem;
            }
        }

        .bu-creation-select {
            height: 3.5rem;
        }

        .bu-creation-input {
            border: 2px solid rgba(0, 0, 0, 0.15);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem;
            border-radius: 6px;

            span {
                color: #D5D9DD;
            }

            .bu-creation-input-label {
                background: #EDEDED;
                padding: 0.625rem 0.75rem;
                color: #8D939B;
                border-radius: 5px;
                font-weight: 600;
                font-size: 1rem;
                @include cursorPointer;
            }

        }

        .bu-creation-helper-text {
            font-size: 0.75rem;
            line-height: 0.875rem;
            color: #ACB2BA;
            width: 100%;
            float: right;
            text-align: right;
        }
    }
}