@import "../../style/sass/main.scss";

.input-field {
  .disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  input,
  textarea {
    background: none;
    color: black;
    font-size: 1rem;
    padding: 0 1.6rem;
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    border: 2px solid rgba(0, 0, 0, 0.15);
    line-height: 3.2rem;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input:focus ~ label,
  input:valid ~ label,
  textarea:focus ~ label,
  textarea:valid ~ label {
    top: -1.16rem;
    font-size: 1rem;
    color: $grey-1;
  }

  label {
    color: $text-color;
    font-size: 1rem;
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: 0.8rem;
    transition: 300ms ease all;
    background-color: $white-color;
    padding: 0.416rem 0.833rem;
    font-weight: 500;
  }

  .bar {
    position: relative;
    display: block;
  }

  .bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $text-color-1;
    transition: 300ms ease all;
    left: 0%;
  }

  .group {
    position: relative;
  }

  .text-body {
    color: $text-color !important;
    font-weight: 500;
  }

  .width100 {
    width: 100%;
  }

  .group i {
    position: absolute;
    top: 1.33rem;
    right: 1.66rem;
  }

  .login-box h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.33rem;
    color: $text-color-1;
  }

  .login-box p {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    color: $text-color;
    margin-bottom: 2.92rem;
  }

  .login-box a:hover {
    color: $text-color-1 !important;
  }

  .login-box .group span {
    font-size: 1rem;
    margin: 0;
    position: absolute;
    bottom: -1.6rem;
    color: $grey-2;
  }

  .login-box p a {
    color: $text-color-1;
  }

  @media only screen and (max-width: 53.3rem) {
    .group i {
      right: 1.66rem;
      top: 0;
      line-height: 5.1rem;
    }
  }
}
